<template>
  <div class="monitor-container">
    <div class="content">
      <div class="left">
        <div class="t-top carder-border">
          <div class="t-title">今日告警</div>
          <div class="t-content">
            <div>
              <div class="card-top">25</div>
              <div class="card-bottom">
                <span>今日告警总数</span>
              </div>
            </div>
            <div>
              <div class="card-top">10</div>
              <div class="card-bottom">
                <span>已处理</span>
              </div>
            </div>
            <div>
              <div class="card-top">15</div>
              <div class="card-bottom">
                <span>未处理</span>
              </div>
            </div>
          </div>
        </div>
        <div class="t-bottom carder-border">
          <div class="t-title">陌生人检测</div>
          <div class="t-content">
            <div>
              <img src="https://oss.mubyte.cn/fsd/face/1.png" alt=""/>
<!--              <div class="name-location">-->
<!--                <div>张某某</div>-->
<!--                <div class="card-top">南门出1</div>-->
<!--              </div>-->
              <div class="card-top">{{ specMonth }}月{{ specDate }}日 9:59:53</div>
            </div>
            <div>
              <img src="https://oss.mubyte.cn/fsd/face/2.png" alt=""/>
<!--              <div class="name-location">-->
<!--                <div>李某某</div>-->
<!--                <div class="card-top">南门出1</div>-->
<!--              </div>-->
              <div class="card-top">{{ specMonth }}月{{ specDate }}日 10:06:27</div>
            </div>
            <div>
              <img src="https://oss.mubyte.cn/fsd/face/3.png" alt=""/>
<!--              <div class="name-location">-->
<!--                <div>王某某</div>-->
<!--                <div class="card-top">南门出1</div>-->
<!--              </div>-->
              <div class="card-top">{{ specMonth }}月{{ specDate }}日 10:23:05</div>
            </div>
            <div>
              <img src="https://oss.mubyte.cn/fsd/face/4.png" alt=""/>
<!--              <div class="name-location">-->
<!--                <div>XXX</div>-->
<!--                <div class="card-top">南门出1</div>-->
<!--              </div>-->
              <div class="card-top">{{ specMonth }}月{{ specDate }}日 11:37:07</div>
            </div>
<!--            <div>-->
<!--              <img src="https://oss.mubyte.cn/fsd/face/5.png" alt=""/>-->
<!--              <div class="card-top">{{ specMonth }}月{{ specDate }}日 11:45:32</div>-->
<!--            </div>-->
<!--            <div>-->
<!--              <img src="https://oss.mubyte.cn/fsd/face/2.png" alt=""/>-->
<!--              <div class="card-top">{{ specMonth }}月{{ specDate }}日 12:06:41</div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="center carder-border">
        <div class="t-top">
          <div class="t-content">
            <div class="t-card">
              <img src="../../../../assets/img/icon-video.png" alt=""/>
              <div class="card-top">10</div>
              <div class="card-bottom">
                <span>摄像机总数</span>
              </div>
            </div>
            <div class="t-card">
              <img src="../../../../assets/img/icon-peoples.png" alt=""/>
              <div class="card-top">171</div>
              <div class="card-bottom">
                <span>今日入场人数</span>
              </div>
            </div>
            <div class="t-card">
              <img src="../../../../assets/img/icon-people.png" alt=""/>
              <div class="card-top">63</div>
              <div class="card-bottom">
                <span>厂内实时人数</span>
              </div>
            </div>
          </div>
        </div>
        <div class="t-bottom">
          <div id="ws-real-player" width="100%" height="700"></div>
        </div>
      </div>
      <div class="right">
        <div class="t-top carder-border">
          <div class="gradient-title">人员行为分析</div>
          <div class="gradient-box">
            <img src="../../../../assets/img/icon-jk.png" alt=""/>
            <div class="main">
              <div class="title-blue">监控</div>
              <div class="desc">运行算法摄像头</div>
            </div>
            <div class="num-blue">2</div>
            <div class="line-blue"></div>
          </div>
          <div class="gradient-box">
            <img src="../../../../assets/img/icon-error.png" alt=""/>
            <div class="main">
              <div class="title-yellow">违规</div>
              <div class="desc">违规抓拍</div>
            </div>
            <div class="num-yellow">17</div>
            <div class="line-yellow"></div>
          </div>
        </div>
        <div class="t-bottom carder-border">
          <div class="gradient-title">违规</div>
          <div class="gradient-chart">
            <ve-histogram
              height="470px"
              :data="chartData"
              :settings="barChartSettings"
              :extend="barChartExtend"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript" src="./lib/WSPlayer/PlaySDKInterface.js"></script>
<script type="text/javascript" src="./lib/WSPlayer/WSPlayer.js"></script>
<script>
import mix from '@/views/statistics-board/board-detail/components/mixin'
import api from '@/api/statistics-board/all-statistics'
import { baseUrl } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import PlayerManager from './icc/PlayerManager.js'

var realPlayer = ''

export default {
  name: 'Monitor',
  components: {},
  mixins: [mix],
  data() {
    return {
      specMonth:'',
      specDate:'',
      chartData: {
        columns: ['月份', '数量'],
        rows: [
          {
            '月份': '5月',
            '数量': 3
          },
          {
            '月份': '6月',
            '数量': 1
          },
          {
            '月份': '7月',
            '数量': 5
          },
          {
            '月份': '8月',
            '数量': 2
          },
          {
            '月份': '9月',
            '数量': 4
          },
          {
            '月份': '10月',
            '数量': 2
          }
        ]
      },
      tableHeight: 100
    }
  },
  computed: {},
  created() {
    this.specMonth = new Date().getMonth() + 1
    this.specDate = new Date().getDate()
  },
  mounted() {
    // this.getChartData()
    this.$nextTick(() => {
      // 初始化播放器
      realPlayer = new PlayerManager({
        el: 'ws-real-player', /** 实时预览容器id，创建多个播放器，传入不同的容器id即可 **/
        type: 'real', /** real - 实时预览  record - 录像回放 **/
        maxNum: 16, /** 一个播放器上限能播放的路数，可根据实际情况设置，支持 1 4 9 16 25 **/
        num: 9, /** 初始化，页面显示的路数 **/
        showControl: true, /** 是否显示工具栏，默认显示 **/
        prefixUrl: 'lib', /** 解码库所在位置的前缀 **/
        receiveMessageFromWSPlayer: (methods, data, err) => { /* 回调函数，可以在以下回调函数里面做监听 */
          switch (methods) {
            case 'initializationCompleted':
              console.log('初始化完成')
              realPlayer.setPlayerAdapter('stretching')
              // 初始化完成，可调用播放方法（适用于动态加载解码库）
              // 若回调未触发时就使用实时预览/录像回放，则无法播放。
              // 此时我们可以调用一个
              break
            case 'changeStreamType': // 主/辅码流切换回调
              console.log(data.channelData) // 通道数据
              console.log(data.streamType) // 码流类型 0-主码流  1-辅码流1  2-辅码流2
              console.log(data.selectIndex) // 窗口序号，从 0
              break
            case 'realSuccess': // 实时预览成功
              console.log('实时预览成功')
              break
            case 'realError': // 实时预览失败
              console.log('实时预览失败', err)
              break
            case 'notifyTalk':
              // 点击窗口上的对讲按钮触发的事件回调
              console.log('notifyTalk', data)
            case 'talkError': // 对讲失败
              console.log('对讲失败')
              break
            case 'selectWindowChanged': // 选中的窗口发生改变
              console.log(data, '返回窗口信息')
              break
            case 'windowNumChanged': // 播放器显示的路数发生改变
              console.log(data, '返回显示的窗口数量')
              break
            case 'closeVideo': // 视频关闭回调
              // 点击关闭按钮引发的视频关闭进行提示
              // 切换视频引发的视频关闭不进行提示
              if (!data.changeVideoFlag) {
                console.log(`窗口${data.selectIndex}的视频已关闭`)
              }
              break
            case 'statusChanged': // 视频状态发生改变

              break
            case 'errorInfo': // 错误信息汇总
              console.log(data, '可打印查看错误消息')
          }
        }
      })
      this.initStart()
    })
  },

  methods: {
    async initStart(){
      let token = await this.getTokenInfo();
      if(!token){
        return false;
      }
      token = JSON.parse(token);
      token = token.data;
      let finalToken = token.token_type + " " + token.access_token;
      sessionStorage.setItem("fsdAuthorization",finalToken)
      let devicesCodes = await this.getDeviceCodes(finalToken);
      devicesCodes = JSON.parse(devicesCodes).data.pageData || [];
      let channelIds = []
      devicesCodes.forEach( d => {
        if(d.isOnline){
          channelIds.push(d.channelCode)
        }
      })
      channelIds = channelIds.join(",")
      // 福斯达特殊处理  通道让写死 ————————Start————————
      channelIds = "1000216$1$0$0,1000214$1$0$0,1000229$1$0$0,1000165$1$0$4,1000165$1$0$10,1000165$1$0$13,1000165$1$0$25,1000165$1$0$30,1000165$1$0$35,1000165$1$0$59"
      // 福斯达特殊处理  通道让写死 ————————End————————
      let rtspUrl = await this.getStartVideo(channelIds,finalToken)
      let FinalRtspUrl = []
      rtspUrl.forEach( r => {
        r = JSON.parse(r).data
        FinalRtspUrl.push(r.url + "?token=" + r.token)
      })
      consoleSelf.info("最终拿到的rtsp地址",FinalRtspUrl);
      FinalRtspUrl.forEach((item,index) => {
        if(index < 11){
          realPlayer.realByUrl({
            rtspURL:item, // 必传
            wsURL:'ws://10.100.100.150:9100', // 必传
            selectIndex: index, // 必传, 播放窗口号
            channelId:"", // 必传, 通道id
            streamType:1, // 必传, 码流类型  0主码流   1辅码流  2辅码流
            playerAdapter: 'stretching', // 选传, selfAdaption 自适应 | stretching 拉伸
          })
        }
      })
    },
    //拿到token信息  Authorization
    async getTokenInfo(){
      return await api.getDahuaToken()
    },
    //拿到固定设备的视频通道code
    async getDeviceCodes(finalToken){
      return await api.getDahuaChannelCodes(finalToken)
    },
    //拿到所有视频通道的rtsp地址
    async getStartVideo(channelIds,finalToken){
      return await api.getDahuaRtspUrl(channelIds,finalToken)
    },
    // async getChartData() {
    //   const res = await api.getTableData(
    //     baseUrl + 'getEquipmentMaintenanceCountResultForDisplayBoard',
    //     {
    //       beginTime: dayjs().subtract(5, 'month').format('YYYY-MM-DD'),
    //       endTime: dayjs().format('YYYY-MM-DD')
    //     }
    //   )
    //   if (res) {
    //     this.chartData.rows = res.map((item) => ({
    //       月份: dayjs(item.month).format('M月'),
    //       数量: Number(item.maintenanceCount)
    //     }))
    //   }
    // },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.32) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";

.monitor-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;

  .content {
    display: flex;
    justify-content: space-between;

    .left {
      width: 450px;

      .t-top {
        position: relative;
        background: #061e3c;

        .t-title {
          padding: 24px 16px 16px 16px;
          font-size: 23px;
          color: #ffffff;
          font-weight: bold;
        }

        .t-content {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-size: 19px;
          color: #ffffff;

          > div {
            width: 289px;
            border-radius: 4px;

            .card-top {
              display: flex;
              justify-content: center;
              color: #00b7ef;
              font-weight: bold;
              font-size: 30px;
            }

            .card-bottom {
              padding: 10px 0 24px 0;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .t-bottom {
        position: relative;
        margin-top: 38px;
        background: #061e3c;

        .t-title {
          padding: 24px 16px 16px 16px;
          font-size: 23px;
          font-weight: bold;
          color: #ffffff;
        }

        .t-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          font-size: 19px;
          gap: 10px 0;
          padding-bottom: 24px;

          img {
            width: 180px;
            height: 120px;
            object-fit: cover;
            border-radius: 2px;
          }

          .name-location {
            padding: 4px 0;
            justify-content: space-between;
            display: flex;
            color: #00b7ef;
            align-items: center;
          }

          > div {
            padding: 10px;
          }

          .card-top {
            color: #00b7ef;
            margin-top: 8px;
          }
        }
      }
    }

    .center {
      position: relative;
      background: #061e3c;

      .t-top {
        width: 100%;

        .t-content {
          display: flex;
          justify-content: space-between;
          font-size: 19px;
          color: #ffffff;

          .t-card {
            position: relative;
            width: 289px;
            border-radius: 4px;
            margin-top: 20px;
            text-align: center;

            &:first-of-type,
            &:last-of-type {
              img {
                width: 60px;
                padding: 12px;
                box-sizing: border-box;
              }
            }

            img {
              width: 60px;
              padding: 10px;
              border-radius: 80px;
              box-sizing: border-box;
              border: 4px solid #0092ef;
              margin-bottom: 4px;
              border: 4px solid transparent;
              border-radius: 80px;
              background-clip: padding-box, border-box;
              background-origin: padding-box, border-box;
              background-image: linear-gradient(to right, #061e3c, #061e3c),
              linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
            }

            .card-top {
              display: flex;
              justify-content: center;
              color: #00b7ef;
              font-weight: bold;
              font-size: 44px;
              padding: 4px 0;
            }

            .card-bottom {
              padding: 6px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .t-bottom {
        margin-top: 24px;
      }
    }

    .right {
      width: 450px;

      .t-top {
        padding-bottom: 12px;
        background-color: #061e3c;
        position: relative;

        .work-order-right {
          div {
            width: 325px !important;
          }
        }
      }

      .gradient-title {
        padding: 24px 16px 16px 16px;

        font-size: 23px;
        font-weight: bold;
      }

      .gradient-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 32px 16px 32px 24px;
        color: #fff;

        img {
          width: 50px;
        }

        .main {
          width: 130px;
          margin-left: 24px;

          .title-blue {
            color: #00b7ef;
            font-size: 26px;
            font-weight: bold;
          }

          .title-yellow {
            color: #ef8f00;
            font-size: 26px;
            font-weight: bold;
          }

          .desc {
            margin-top: 6px;
          }
        }

        .num-blue {
          width: 70px;
          text-align: center;
          margin-left: 32px;
          color: #00b7ef;
          font-size: 40px;
          font-weight: bold;
        }

        .num-yellow {
          width: 70px;
          text-align: center;
          margin-left: 32px;
          color: #ef8f00;
          font-size: 40px;
          font-weight: bold;
        }

        .line-blue {
          width: 16px;
          height: 40px;
          background: #00b7ef;
          border-radius: 12px;
          background-image: linear-gradient(-60deg, #6297db 48%, #1eceff 100%);
        }

        .line-yellow {
          width: 16px;
          height: 40px;
          background: #ef8f00;
          border-radius: 12px;
          background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
        }
      }

      .t-bottom {
        position: relative;
        margin-top: 48px;
        display: block;
        background-color: #061e3c;
      }

      .gradient-chart {
        padding: 0 10px;
      }
    }

    .carder-border {
      &::before {
        content: "";
        position: absolute;
        bottom: -4px;
        right: -4px;
        border-right: 4px solid #0092ef;
        border-bottom: 4px solid #0092ef;
        width: 16px;
        height: 16px;
        border-radius: 2px;
      }

      &::after {
        content: "";
        position: absolute;
        left: -4px;
        top: -4px;
        border-left: 4px solid #0092ef;
        border-top: 4px solid #0092ef;
        width: 16px;
        height: 16px;
        border-radius: 2px;
      }
    }
  }
}
</style>
