var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-wrap" },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "group" }, [
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  { staticClass: "title", on: { click: _vm.onAddRole } },
                  [_vm._v("添加角色")]
                )
              ]),
              _c(
                "div",
                { staticClass: "content" },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "Warehouse",
                      class: { active: item.id === _vm.currentId },
                      on: {
                        click: function($event) {
                          return _vm.onChangeRole(item, index)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "ellipsis" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _c("div", [
                        _vm.permission("RoleModify")
                          ? _c("i", {
                              staticClass: "modify",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.modifyOrCopy(item, "modify")
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.permission("RoleDel")
                          ? _c("i", {
                              staticClass: "del",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.delRoles(item)
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "model-wrap-right common-tab" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "m-radio-group",
                    on: { change: _vm.radioChange },
                    model: {
                      value: _vm.params.radio,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "radio", $$v)
                      },
                      expression: "params.radio"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "Role" } }, [
                      _vm._v("功能权限")
                    ]),
                    _c("el-radio-button", { attrs: { label: "User" } }, [
                      _vm._v("关联用户")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.params.radio === "Role"
              ? _c(
                  "div",
                  { staticStyle: { margin: "10px 20px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "all-btns" },
                      [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("网页端功能权限")
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "role-btn",
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.saveRole }
                          },
                          [_vm._v("保存")]
                        )
                      ],
                      1
                    ),
                    _vm.openPanel
                      ? _c("el-tree", {
                          ref: "tree",
                          attrs: {
                            "show-checkbox": "",
                            "node-key": "name",
                            data: _vm.constantRoutes,
                            props: _vm.defaultProps,
                            "default-expanded-keys": ["all"],
                            "default-checked-keys": _vm.auths,
                            "render-content": _vm.renderContent
                          }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "el-form-item" }, [
                      _c("label", { staticClass: "el-form-item__label" }, [
                        _vm._v("移动端功能权限")
                      ])
                    ]),
                    _vm.openPanelApp
                      ? _c("el-tree", {
                          ref: "treeApp",
                          attrs: {
                            "show-checkbox": "",
                            "node-key": "name",
                            data: _vm.appMenu,
                            props: _vm.defaultProps,
                            "default-expanded-keys": ["全部"],
                            "default-checked-keys": _vm.authApp,
                            "render-content": _vm.renderContentApp
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.params.radio === "User"
              ? _c(
                  "div",
                  { staticStyle: { margin: "10px 20px" } },
                  [
                    _c("MTable", {
                      ref: "mTableUser",
                      attrs: {
                        columns: _vm.getColumns,
                        height: _vm.height,
                        "set-data-method": _vm.getTableDatas,
                        "columns-setting": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "organizationId",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(_vm._s(row.organizationName))
                              ])
                            }
                          },
                          {
                            key: "isSystemUser",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(+row.isSystemUser === 1 ? "是" : "否")
                                )
                              ])
                            }
                          },
                          {
                            key: "station",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewStation(row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            }
                          },
                          {
                            key: "setting",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewSetting(row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            }
                          },
                          {
                            key: "roleList",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                _vm._l(row.roleList, function(item) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: item.id,
                                      staticClass: "role-tags",
                                      attrs: { color: "#F6F7F9" }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              )
                            }
                          },
                          {
                            key: "isEnabled",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                { staticClass: "isEnabled" },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      value: row.isEnabled === 1,
                                      "active-color": "#13ce66",
                                      "inactive-color": "#eee"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.switchStatusUser(row, "User")
                                      }
                                    }
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(row.isEnabled ? "启用" : "停用")
                                    )
                                  ])
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  row.isSystem === 0
                                    ? [
                                        _vm.permission("UserSetStations") &&
                                        _vm.memberCode != "mom"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setStation(row)
                                                  }
                                                }
                                              },
                                              [_vm._v("设置工位")]
                                            )
                                          : _vm._e(),
                                        _vm.permission("UserSetStations") &&
                                        _vm.memberCode != "mom"
                                          ? _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            })
                                          : _vm._e(),
                                        _vm.permission("UserSetEquipments") &&
                                        _vm.memberCode != "mom"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setEquipment(row)
                                                  }
                                                }
                                              },
                                              [_vm._v("设置设备")]
                                            )
                                          : _vm._e(),
                                        _vm.permission("UserSetEquipments") &&
                                        _vm.memberCode != "mom"
                                          ? _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            })
                                          : _vm._e(),
                                        _vm.memberCode === "xwhj-sh"
                                          ? [
                                              _vm.permission("UserModify")
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.modifyOrCopy(
                                                            row,
                                                            "modify"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" 修改 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.permission("UserModify")
                                                ? _c("el-divider", {
                                                    attrs: {
                                                      direction: "vertical"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.permission("UserDel")
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.dels(row)
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" 删除 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.permission("UserDel") &&
                                              _vm.isSystem
                                                ? _c("el-divider", {
                                                    attrs: {
                                                      direction: "vertical"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.isSystem
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetPassword(
                                                            row
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("重置密码")]
                                                  )
                                                : _vm._e()
                                            ]
                                          : _vm._e(),
                                        _vm.memberCode !== "xwhj-sh"
                                          ? _c(
                                              "el-dropdown",
                                              {
                                                on: {
                                                  command: function(command) {
                                                    _vm.more(row, command)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-dropdown-link"
                                                  },
                                                  [
                                                    _vm._v(" 更多"),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-arrow-down el-icon--right"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "el-dropdown-menu",
                                                  {
                                                    staticClass:
                                                      "td-dropdown-menu",
                                                    attrs: { slot: "dropdown" },
                                                    slot: "dropdown"
                                                  },
                                                  [
                                                    _vm.permission("UserModify")
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "modify"
                                                            }
                                                          },
                                                          [_vm._v("修改")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.permission("UserDel")
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "del"
                                                            }
                                                          },
                                                          [_vm._v("删除")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.isSystem
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command:
                                                                "resetPassword"
                                                            }
                                                          },
                                                          [_vm._v("重置密码")]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    : [
                                        _vm.isSystem
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.resetPassword(
                                                      row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("重置密码")]
                                            )
                                          : _vm._e()
                                      ]
                                ],
                                2
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        4202896080
                      )
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        2
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "设置工位" },
          on: { onOk: _vm.submitStation },
          model: {
            value: _vm.stationVisible,
            callback: function($$v) {
              _vm.stationVisible = $$v
            },
            expression: "stationVisible"
          }
        },
        [
          _c("MElTransfer", {
            attrs: {
              data: _vm.formOptions.stationList,
              props: { key: "id", label: "name" },
              titles: ["源列表", "目的列表"],
              filterable: true,
              "filter-placeholder": "请输入名称"
            },
            model: {
              value: _vm.selectStationList,
              callback: function($$v) {
                _vm.selectStationList = $$v
              },
              expression: "selectStationList"
            }
          })
        ],
        1
      ),
      _c("select-setting", {
        ref: "selectSetting",
        attrs: {
          visible: _vm.setsVisible,
          "select-materials": _vm.selectEquipment
        },
        on: {
          "update:visible": function($event) {
            _vm.setsVisible = $event
          },
          submitForm: _vm.submitSetting
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "查看设备" },
          on: {
            onOk: function($event) {
              _vm.setVisible = false
            }
          },
          model: {
            value: _vm.setVisible,
            callback: function($$v) {
              _vm.setVisible = $$v
            },
            expression: "setVisible"
          }
        },
        [
          _c("MTable", {
            ref: "mTables",
            attrs: {
              columns: _vm.viewSetColumns,
              height: 320,
              data: _vm.equipmentList,
              "show-page": false,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "drawing",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.drawing
                        ? _c("el-image", {
                            staticClass: "drawing-img",
                            attrs: {
                              src: row.drawing,
                              "preview-src-list": _vm.fixImg(row.drawing)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isSystems === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.resetEquipment(row)
                                }
                              }
                            },
                            [_vm._v("解绑")]
                          )
                        : _c(
                            "el-button",
                            { attrs: { type: "text", disabled: "" } },
                            [_vm._v("解绑")]
                          )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("TableDialog", {
        attrs: {
          title: "查看用户",
          show: _vm.userVisible,
          columns: _vm.viewTableColumns,
          "table-data": _vm.viewTableData
        },
        on: {
          "update:show": function($event) {
            _vm.userVisible = $event
          }
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "修改", width: "500px" },
          on: { onOk: _vm.submitFormUser },
          model: {
            value: _vm.visibles,
            callback: function($$v) {
              _vm.visibles = $$v
            },
            expression: "visibles"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuilds",
            attrs: {
              "label-position": "right",
              "form-data": _vm.formDataUser,
              "form-list": _vm.formLists["User"],
              "custom-rules": _vm.rules
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }