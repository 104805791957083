<template>
  <div class="scrap-wrap">
    <!--    <div @click="showlog">123123131</div>-->
    <div class="containter">
      <div class="wrap-left">
        <div class="wrap-left-top">
          <img src="@/assets/l_c_M_images/demand.png" class="img">
          <span>需求中心</span>
        </div>
        <div class="wrap-left-bottom">
          <div
            v-for="(item, index) in initTabs"
            :key="index"
            :class="currentIdx === index ? 'wrap-left-li active' : 'wrap-left-li'"
            @click="changeIdx(index)"
          >
            <span>{{ item.title }}</span>
            <span class="tab-right">{{ item.count }}</span>
          </div>
        </div>
      </div>
      <div class="wrap-right">
        <div class="top">
          <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data.sync="searchFormData"
                      @search="searchTable"/>
        </div>
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            :search-title="functionHeader.searchTitle"
            :tags="+currentIdx === 4 ? [] : functionHeader.tags"
            :columns-key="columnsKey"
            :export-name="+currentIdx=== 4 ? functionHeader.exportName : ''"
            :export-params="functionHeader.exportParams"
            :show-export="+currentIdx === 4 && selectionData.length ? false : true"
            @search="$refs.mTable.setTableData(true)"
          >
            <div v-if="getTableDataParams.searchType === '' && selectionData.length" slot="all">
              <el-button v-if="permission('Export')" type="primary" size="small" @click="allMore('batchExport')">
                批量导出
              </el-button>
              <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                已选
                <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                / {{ total }}项
              </span>
              <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
            </div>
          </FunctionHeader>
          <MTable
            ref="mTable"
            :columns="newColumns"
            :height="height"
            :columns-key="columnsKey"
            :set-data-method="getTableData"
            :selectShowFalse="+currentIdx === 4"
            @selection-change="selectionChange"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap" type="text"
                       @click="viewDetail(row)">{{ row.code }}
            </el-button>
            <div slot="content" slot-scope="{ row }">
              {{ demandServiceTypes[row.content].name }}
            </div>
            <div slot="serviceType" slot-scope="{ row }">
              {{ row.serviceType || row.serviceType === 0 ? demandServiceContentType[row.serviceType].name : '' }}
            </div>
            <div slot="status" slot-scope="{ row }">
              {{
                row.content === 3 ? reformStatus[row.status].name : row.status === 10 ? '已完成' : demandStatus[row.status].name
              }}
            </div>
            <div slot="planStartTime" slot-scope="{ row }">{{ getTimeDescNo(row.planStartTime, row.planEndTime) }}</div>
            <div slot="createTime" slot-scope="{ row }">{{ getTimeDesc(row.createTime) }}</div>

            <div slot="action" slot-scope="{ row }">
              <!-- 改造 -->
              <template v-if="row.content === 3">
                <template v-if="getTableDataParams.searchType === 0">
                  <template v-if="row.status === 0">
                    <el-button v-if="permission('UploadPlan')" type="text" @click="uploadPlan(row)">上传技术方案
                    </el-button>
                    <!-- <el-divider v-if="permission('UploadPlan')" direction="vertical" />
                    <el-button v-if="permission('Send')" type="text" @click="sends(row)">转交</el-button>
                    <el-divider v-if="permission('Reset') && fixUsers(row.technicalHeadName)" direction="vertical" />
                    <el-button v-if="permission('Reset') && fixUsers(row.technicalHeadName)" type="text" @click="withdraw(row, 3)">退回</el-button> -->
                  </template>
                  <template v-if="row.status === 2">
                    <el-button v-if="permission('UploadPlanAgain')" type="text" @click="uploadPlan(row, 1)">继续上传
                    </el-button>
                    <el-divider v-if="permission('UploadPlanAgain')" direction="vertical"/>
                    <el-button v-if="permission('Save')" type="text" @click="savePlan(row, 2)">提交</el-button>
                  </template>
                  <template v-if="row.status === 3">
                    <el-button v-if="permission('Write')" type="text" @click="writePlan(row)">填写</el-button>
                    <!-- <el-divider v-if="permission('Write')" direction="vertical" />
                    <el-button v-if="permission('Send')" type="text" @click="sends(row)">转交</el-button>
                    <el-divider v-if="permission('Reset') && fixUsers(row.creator)" direction="vertical" />
                    <el-button v-if="permission('Reset') && fixUsers(row.creator)" type="text" @click="withdraw(row, 3)">退回</el-button> -->
                  </template>
                  <template v-if="row.status === 4 || row.status === 5">
                    <el-button v-if="permission('Approval')" type="text" @click="approvals(row, row.status)">审批
                    </el-button>
                    <!-- <el-divider v-if="permission('Approval')" direction="vertical" />
                    <el-button v-if="permission('Send')" type="text" @click="sends(row)">转交</el-button>
                    <el-divider v-if="permission('Reset') && fixUsers(row, row.status)" direction="vertical" />
                    <el-button v-if="permission('Reset') && fixUsers(row, row.status)" type="text" @click="withdraw(row, 3)">退回</el-button> -->
                  </template>
                  <template v-if="row.status === 6">
                    <el-button v-if="permission('Start')" type="text" @click="startPlan(row, 8)">开始执行</el-button>
                  </template>
                  <template v-if="row.status === 7">
                    <el-button v-if="permission('StartAgain')" type="text" @click="startAgain(row)">继续执行</el-button>
                    <el-divider v-if="permission('StartAgain')" direction="vertical"/>
                    <el-button v-if="permission('Del')" type="text" @click="finish(row, 9)">已完成</el-button>
                  </template>
                  <template v-if="row.status === 8 || row.status === 9">
                    <el-button v-if="permission('Approval')" type="text" @click="approvals(row, row.status, 1)">审批
                    </el-button>
                  </template>
                  <template v-if="row.status === 1">
                    <el-button v-if="permission('Modify')" type="text" @click="modifys(row, 'modify')">修改</el-button>
                    <el-divider v-if="permission('Modify')" direction="vertical"/>
                    <el-button v-if="permission('Del')" type="text" @click="delMand(row)">删除</el-button>
                  </template>
                </template>
                <template v-if="getTableDataParams.searchType === 1">
                  <el-button v-if="permission('Withdraw') && row.status !== 7" type="text" @click="withdraw(row, 4)">
                    撤回
                  </el-button>
                </template>
                <!-- <template v-if="getTableDataParams.searchType === 3">
                  <template v-if="row.status === 10">
                    <el-button v-if="permission('Init') && fixUser(row.creatorIds)" type="text" @click="initAgain(row, 1)">重开</el-button>
                  </template>
                </template> -->
                <template v-if="getTableDataParams.searchType === 3">
                  <template v-if="row.status === 10">
                    <el-button v-if="permission('Reset')" type="text" @click="withdraw(row, 5)">退回</el-button>
                    <el-divider v-if="permission('DelRequirement')" direction="vertical"/>
                    <el-button v-if="permission('DelRequirement')" type="text" @click="DelRequirement(row)">删除
                    </el-button>
                  </template>
                </template>
                <template v-if="getTableDataParams.searchType === ''">
                  <el-button v-if="permission('Reset')" type="text" @click="withdraw(row, 5)">退回</el-button>
                  <el-divider v-if="permission('DelRequirement')" direction="vertical"/>
                  <el-button v-if="permission('DelRequirement')" type="text" @click="DelRequirement(row)">删除
                  </el-button>
                </template>
              </template>
              <!-- 维修、保养、装机、点检计划、保养计划、点检-->
              <template v-else>
                <!-- 待处理 -->
                <template v-if="getTableDataParams.searchType === 0">
                  <template v-if="row.status === 0">
                    <el-button v-if="permission('Dispatch')" type="text" @click="dispatchs(row)">派工</el-button>
                    <el-divider v-if="permission('Dispatch')" direction="vertical"/>
                    <el-button v-if="permission('Modify')" type="text" @click="modifys(row, 'modify')">修改</el-button>
                    <el-divider v-if="permission('Modify')" direction="vertical"/>
                    <el-button v-if="permission('Del') && [0, 1, 2, 3, 6,7].includes(row.content)" type="text"
                               @click="delMand(row)">删除
                    </el-button>
                    <el-button v-if="permission('Skip') && [4, 5].includes(row.content)" type="text"
                               @click="savePlan(row, 7)">跳过
                    </el-button>
                  </template>
                  <template v-if="row.status === 2">
                    <el-button v-if="permission('AddPlan')" type="text" @click="addPlan(row)">填报</el-button>
                    <el-divider v-if="permission('AddPlan')" direction="vertical"/>
                    <!-- <el-button v-if="permission('Send')" type="text" @click="sends(row)"> 转交</el-button>
                    <el-divider v-if="permission('Send')" direction="vertical" /> -->
                    <el-button v-if="permission('Reset')" type="text" @click="withdraw(row, 2)">退回</el-button>
                  </template>
                  <template v-if="row.status === 1">
                    <el-button v-if="permission('ApprovalPlan')" type="text" @click="approvalPlan(row)">审批出差计划
                    </el-button>
                  </template>
                  <template v-if="row.status === 3">
                    <el-button v-if="permission('ModifyPlan')" type="text" @click="approvalPlan(row, 1)">修改出差计划
                    </el-button>
                    <el-divider v-if="permission('ModifyPlan')" direction="vertical"/>
                    <!-- <el-button v-if="permission('Send')" type="text" @click="sends(row)"> 转交</el-button>
                    <el-divider v-if="permission('Send')" direction="vertical" /> -->
                    <el-button v-if="permission('Reset')" type="text" @click="withdraw(row, 2)">退回</el-button>
                  </template>
                  <template v-if="row.status === 4">
                    <el-button v-if="permission('Start')" type="text" @click="startPlan(row, 2)">开始执行</el-button>
                    <el-divider v-if="permission('Start')" direction="vertical"/>
                    <!-- <el-button v-if="permission('Send') && fixUser(row.creatorIds)" type="text" @click="sends(row)"> 转交</el-button>
                    <el-divider v-if="permission('Send') && fixUser(row.creatorIds)" direction="vertical" /> -->
                    <el-button v-if="permission('Reset')" type="text" @click="withdraw(row, 2)">退回</el-button>
                  </template>
                  <template v-if="row.status === 5">
                    <el-button v-if="permission('StartAgain')" type="text" @click="startAgain(row)">继续执行</el-button>
                    <el-divider v-if="permission('StartAgain')" direction="vertical"/>
                    <el-button v-if="permission('Finish')" type="text" @click="finish(row, 3)">已完成</el-button>
                  </template>
                  <template v-if="row.status === 6">
                    <el-button v-if="permission('Approval')" type="text" @click="startAgain(row)">审批</el-button>
                  </template>
                </template>
                <!-- 我发起 -->
                <template v-if="getTableDataParams.searchType === 1">
                  <el-button v-if="permission('Withdraw') && (row.status === 2 || row.status === 4)" type="text"
                             @click="withdraw(row, 1)">
                    撤回
                  </el-button>
                </template>
                <!-- 归档需求 -->
                <template v-if="getTableDataParams.searchType === 3">
                  <template v-if="row.status === 10">
                    <el-button v-if="permission('Reset')" type="text" @click="withdraw(row, 5)">退回</el-button>
                    <el-divider v-if="permission('DelRequirement')" direction="vertical"/>
                    <el-button v-if="permission('DelRequirement')" type="text" @click="DelRequirement(row)">删除
                    </el-button>
                  </template>
                </template>
                <!-- 所有服务 -->
                <template v-if="getTableDataParams.searchType === ''">
                  <el-button v-if="permission('Reset')" type="text" @click="withdraw(row, 5)">退回</el-button>
                  <el-divider v-if="permission('DelRequirement')" direction="vertical"/>
                  <el-button v-if="permission('DelRequirement')" type="text" @click="DelRequirement(row)">删除
                  </el-button>
                </template>
              </template>
            </div>
          </MTable>
        </div>
      </div>
    </div>
    <MDialog v-model="resetVisible" :append-to-body="true" title="退回" width="500px" @onOk="submitFormReset">
      <MFormBuilder ref="resetForm" :form-list="resetFormList" label-position="right"/>
    </MDialog>
    <MDialog v-model="approvalVisible" :append-to-body="true" title="审批" width="500px">
      <div>
        <MFormBuilder
          ref="approvalStatusForm"
          :form-list="approvalFormList"
          :form-data="approvalFormData"
          label-position="right"
          class="extra-froms"
        />
      </div>
      <div slot="footer" class="mubai-dialog-footer center-content">
        <el-button @click="approvalVisible = false">取消</el-button>
        <el-button type="danger" @click="submitFormApproval(0)">驳回</el-button>
        <el-button type="primary" @click="submitFormApproval(1)">通过</el-button>
      </div>
    </MDialog>
    <MDialog v-model="uploadVisible" :append-to-body="true" title="上传技术方案" width="500px" @onOk="submitFormPlan">
      <div>
        <el-upload ref="upload" drag multiple class="upload-demo" :file-list="fileList" v-bind="uploadProp">
          <i class="el-icon-upload"/>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg..</div>
        </el-upload>
      </div>
    </MDialog>
    <MDialog v-model="delVisible" :append-to-body="true" :title="titles[currentStatus]" width="500px"
             @onOk="submitForm">
      <MFormBuilder
        ref="baseStatusForm"
        :form-list="currentStatus === 0 ? statusFormList : usersFormList"
        :form-data="statusFormData"
        label-position="right"
        class="extra-froms"
      />
    </MDialog>
    <DispatchUser :visible.sync="showDispatch" :base-info="baseInfo" @refrash="refrashList"/>
  </div>
</template>

<script>
import DispatchUser from '@/views/service/demand-center/components/dispatch-user'
import conventionMixin from '@/mixin/conventional-page'
import DelPopover from '@/components/DelPopover/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/xiewei/service/demand-center'
import { columns } from './columns'
import { searchFormList, approvalFormList, resetFormList } from './form-list'
import TableDialog from '@/components/TableDialog/TableDialog'
import {
  demandServiceTypes,
  demandServiceType,
  demandStatus,
  reformStatus,
  demandServiceContentType
} from '@/config/options.sales'
import uApi from '@/api/sets/user/user'
import keApi from '@/api/information/unit'
import { Encrypt } from '@/utils/sercet'
import { getUUid, getTimeDescNo, getTimeDesc, setTreeData, nameChangeForBB } from '@/utils'
import { getToken } from '@/utils/auth'

export default {
  name: 'DemandCenterList',
  components: { DispatchUser, TableDialog, DelPopover, SearchForm, FunctionHeader },
  mixins: [conventionMixin],
  async created() {
    // handleColumns(this.columnsSetName,columns).then(res => this.newColumns = [...res])
    if (['bbelc'].includes(sessionStorage.getItem('memberCode'))) {
      const had = this.newColumns.filter(p => p.prop === 'happenTime')
      if (!had.length) {
        this.newColumns.splice(8, 0, {
          prop: 'happenTime',
          label: '发生时间',
          sortable: false,
          hiddenSearch: true
        })
      }
    }
    await this.getUser()
    this.searchFormList[4].children[0].options = this.userList
    this.searchFormList[6].children[0].options = this.userList
  },
  data() {
    return {
      columnsSetName: '需求中心',
      api,
      demandServiceTypes,
      demandServiceContentType,
      resetFormList,
      getTimeDescNo,
      getTimeDesc,
      demandStatus,
      reformStatus,
      approvalFormList,
      resetVisible: false,
      showDispatch: false,
      approvalFormData: {},
      approvalVisible: false,
      uploadVisible: false,
      currentIdx: 0,
      fileList: [],
      attachmentsList: [],
      uploadProp: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange,
        onPreview: this.handlePreview,
        beforeUpload: this.beforeAvatarUpload
      },
      statusFormList: [
        {
          colSpan: 24,
          label: '负责人',
          key: 'principalId',
          tagName: 'el-select',
          required: true,
          props: {
            clearable: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        }
      ],
      usersFormList: [
        {
          colSpan: 24,
          label: '审批人',
          key: 'principalId',
          tagName: 'el-select',
          required: true,
          props: {
            clearable: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        }
      ],
      titles: ['转交', '提交审批'],
      currentStatus: 0,
      baseInfo: {
        facilityDemandId: undefined,
        facilityDemandCode: ''
      },
      statusFormData: {},
      editableTabs: [
        {
          title: '待处理服务需求',
          count: 0
        },
        {
          title: '我发起的',
          count: 0
        },
        {
          title: '我处理的',
          count: 0
        },
        {
          title: '归档需求',
          count: 0
        },
        {
          title: '所有服务需求',
          count: 0
        }
      ],
      initTabs: [],
      demandServiceType,
      params: {
        radio: 'FacilityDemand'
      },
      newColumns: columns,
      searchFormList,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        searchTitle: '需求编号',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addAll
            }
          }
        ],
        exportName: nameChangeForBB() ? 'exportFacilityDemandBbelc' : 'exportFacilityDemand',
        exportParams: {}
      },
      formData: {},
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      currentType: 'add',
      getTableDataParams: {
        searchType: 0
      },
      searchKeyword: true,
      selectionData: [],
      delVisible: false,
      isDel: 1,
      selectId: '',
      userList: [],
      signs: 0,
      searchFormData: {},
      allCustomer: [],
      exportSelectionIds: [],
      total: 0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 25
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    const init = this._.cloneDeep(this.editableTabs)
    init.splice(4, 1)
    this.initTabs = this.permission('AllList') ? this.editableTabs : init
    this.getAllCustomer()
    this.getFacilityDemandCount()
  },
  methods: {
    showlog() {
      consoleSelf.info('1', this.newColumns)
    },
    fixUsers(name, val) {
      if (val) {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        return +val === 4 ? userInfo.name !== name.saleHeadName : userInfo.name !== name.rdHeadName
      } else {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        return userInfo.name !== name
      }
    },
    approvals(row, val, type) {
      this.signs = +type === 1 ? +val - 2 : val
      this.approvalVisible = true
      this.baseInfo = this._.cloneDeep(row)
    },
    writePlan(row) {
      this.$router.push({
        name: 'addLocalDevelopment',
        query: { id: row.id, type: 'add' }
      })
    },
    async savePlan(row, val) {
      const res = await api.modifyFacilityDemandOperate({
        sign: val,
        demandId: row.id,
        content: +row.content === 3 ? 3 : 0
      })
      if (res) {
        this.$message.success(`${+val === 2 ? '提交成功' : '跳过成功'}`)
        this.getFacilityDemandCount()
        this.$refs.mTable.setTableData(true)
      }
    },
    async submitFormApproval(status) {
      const { form } = this.$refs.approvalStatusForm
      const res = await api.modifyFacilityDemandOperate({
        sign: this.signs,
        demandId: this.baseInfo.id,
        content: 3,
        status: status,
        updateRemark: form.updateRemark
      })
      if (res) {
        this.getFacilityDemandCount()
        this.$refs.mTable.setTableData(true)
        this.approvalVisible = false
      }
    },
    async submitFormPlan(callback) {
      const res = await api.modifyFacilityDemandOperate({
        sign: this.signs,
        demandId: this.baseInfo.id,
        content: +this.baseInfo.content === 3 ? 3 : 0,
        attachmentsList: this.attachmentsList.map(item => {
          return {
            facilityDemandId: this.baseInfo.id,
            fileName: item.name,
            ossAddress: item.url
          }
        })
      })
      if (res) {
        this.getFacilityDemandCount()
        this.$refs.mTable.setTableData(true)
        this.uploadVisible = false
      }
      callback(true)
    },
    async uploadPlan(row, val) {
      if (+val === 1) {
        await this.getFacilityDemandAttachmentsList(row.id)
      }
      this.signs = +val === 1 ? 1 : 0
      this.baseInfo = this._.cloneDeep(row)
      this.uploadVisible = true
    },
    // 获取文件
    async getFacilityDemandAttachmentsList(id) {
      const res = await api.getFacilityDemandAttachmentsList({
        demandId: id
      })
      if (res) {
        this.attachmentsList = res.map(item => {
          return {
            ...item,
            name: item.fileName,
            url: item.ossAddress
          }
        })
        this.fileList = this._.cloneDeep(this.attachmentsList)
      }
    },
    fileRemove(file, fileList) {
      this.attachmentsList =
        fileList.length > 0
          ? fileList.map(file => {
            return {
              uid: file.uid,
              name: file.name,
              url: file.response.result
            }
          })
          : []
    },
    // 上传附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.attachmentsList.push({
          uid: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    fixUser(val) {
      if (val) {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        return userInfo ? val.split('^').includes(userInfo.id.toString()) : false
      }
      return false
    },
    async getFacilityDemandCount() {
      const res = await api.getFacilityDemandCount()
      if (res) {
        this.initTabs[0].count = res[0]
        this.initTabs[1].count = res[1]
        this.initTabs[2].count = res[2]
        this.initTabs[3].count = res[3]
        this.initTabs[4].count = res[4]
      }
    },
    async initAgain(row, val) {
      this.$confirm('重开后将重新走服务流程，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.modifyFacilityDemandOperate({
          sign: +val === 1 ? 10 : 5,
          demandId: row.id,
          content: +row.content === 3 ? 3 : 0
        })
        if (res) {
          this.$message.success('重开成功')
          this.getFacilityDemandCount()
          this.$refs.mTable.setTableData(true)
        }
      })
    },
    approvalPlan(row, val) {
      if (+val === 1) {
        this.$router.push({
          name: 'addTravelPlan',
          query: { id: row.id, code: row.code, type: 'modify' }
        })
      } else {
        this.$router.push({
          name: 'addTravelPlan',
          query: { id: row.id, code: row.code, status: 1, type: 'view' }
        })
      }
    },
    addPlan(row) {
      this.$router.push({
        name: 'addTravelPlan',
        query: { id: row.id, code: row.code, type: 'add' }
      })
    },
    startAgain(row) {
      this.$router.push({
        name: 'demandCenterDetail',
        query: { id: row.id }
      })
    },
    async finish(row, val) {
      const res = await api.modifyFacilityDemandOperate({
        sign: val,
        demandId: row.id,
        content: +row.content === 3 ? 3 : 0
      })
      if (res) {
        this.$message.success('已完成成功')
        this.getFacilityDemandCount()
        this.$refs.mTable.setTableData(true)
      }
    },
    async startPlan(row, val) {
      const res = await api.modifyFacilityDemandOperate({
        sign: val,
        demandId: row.id,
        content: +row.content === 3 ? 3 : 0
      })
      if (res) {
        this.$router.push({
          name: 'demandCenterDetail',
          query: { id: row.id }
        })
      }
    },
    async sends(row) {
      this.baseInfo = this._.cloneDeep(row)
      await this.getUser()
      this.delVisible = true
    },
    async withdraw(row, val) {
      if (+val === 2) {
        this.baseInfo = this._.cloneDeep(row)
        this.resetVisible = true
        return false
      }
      if (+val === 5) {
        this.$confirm('该需求将退回到上一个审批节点重新审批，是否确认？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.modifyFacilityDemandOperate({
            sign: 13,
            demandId: row.id,
            content: +row.content === 3 ? 3 : 0
          })
          if (res) {
            this.$message.success(`${+val === 1 || +val === 4 ? '撤回成功' : '退回成功'}`)
            this.getFacilityDemandCount()
            this.$refs.mTable.setTableData(true)
          }
        })
      } else {
        const res = await api.modifyFacilityDemandOperate({
          sign: +val === 1 ? 6 : +val === 2 ? 0 : +val === 3 ? 12 : +val === 4 ? 11 : 13,
          demandId: row.id,
          content: +row.content === 3 ? 3 : 0
        })
        if (res) {
          this.$message.success(`${+val === 1 || +val === 4 ? '撤回成功' : '退回成功'}`)
          this.getFacilityDemandCount()
          this.$refs.mTable.setTableData(true)
        }
      }
    },
    dispatchs(row) {
      this.baseInfo = this._.cloneDeep(row)
      this.showDispatch = true
    },
    refrashList() {
      this.showDispatch = false
      this.getFacilityDemandCount()
      this.$refs.mTable.setTableData(true)
    },
    // 获取客户
    // async getCustomer() {
    //   const res = await keApi.getCustomerList({
    //     searchVal: Encrypt(
    //       JSON.stringify([
    //         {
    //           id: getUUid(),
    //           fieldName: 'isEnabled',
    //           fieldType: 'number',
    //           fieldValue: 1,
    //           operator: 'eq'
    //         }
    //       ])
    //     )
    //   })
    //   if (res) {
    //     this.searchFormList[1].children[0].options = res
    //   }
    // },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.searchFormList[1].props.options = setTreeData(data)
      }
    },
    // 获取系统用户
    async getUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'isSystemUser',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.userList = res
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.statusFormList[0].children[0].options = res.filter(item => item.id !== userInfo.id)
        this.usersFormList[0].children[0].options = res
      }
    },
    changeIdx(idx) {
      this.currentIdx = idx
      // this.searchFormData = {}
      // this.searchFormatData = []
      this.getFacilityDemandCount()
      // let news = this._.cloneDeep(columns)
      // if (+this.currentIdx === 4 || +this.currentIdx === 3) {
      //   news.splice(news.length - 1, 1)
      //   this.newColumns = news
      // } else {
      //   this.newColumns = columns
      // }
      this.newColumns = columns
      this.params.radio = +this.currentIdx === 4 ? 'AllFacilityDemand' : 'FacilityDemand'
      this.getTableDataParams.searchType = +this.currentIdx === 4 ? '' : idx
      // this.$refs.functionHeader.exportAndImportTags[0].hidden = +this.currentIdx === 4 ? !this.permission('Export') : true
      this.$refs.mTable.setTableData(true)
    },
    selectionChange(data) {
      this.selectionData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    allMore(command) {
      const functions = {
        batchExport: () => {
          this.batchExport()
        }
      }
      functions[command]()
    },
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'demandCenterDetail',
        query: { id: row.id, isAll: +this.currentIdx === 4 ? 1 : 0 }
      })
    },
    addAll() {
      this.$router.push({ name: 'addDemandCenter', query: { type: 'add' } })
    },
    searchTable(data) {
      const otherSrcInfo = data.length > 0 ? data.filter(item => item.fieldName) : []
      consoleSelf.info('要查询的数据', data)
      this.params.radio = +this.currentIdx === 4 ? 'AllFacilityDemand' : 'FacilityDemand'
      const list = data.length > 0 ? data.filter(item => item.fieldName !== 'content') : []
      const val = data.length > 0 ? data.filter(item => item.fieldName === 'content') : []
      this.getTableDataParams.content = val.length > 0 ? val[0].fieldValue : ''
      const ids = list.map(item => item.fieldName)
      const hasIndex = ids.indexOf('customerName')
      this.searchFormatData = this._.cloneDeep(list)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        const id = list[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const lists = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [
          ...this.searchFormatData,
          {
            id: getUUid(),
            fieldName: lists[0].isCustomer === 1 ? 'customerSiteId' : 'customerId',
            fieldType: 'number',
            fieldValue: lists[0].isCustomer === 1 ? lists[0].id : arr[0].id,
            operator: 'eq'
          }
        ]
      }
      // 处理新增的筛选条件 ————————Start————————

      if (data.length > 0) {
        data.forEach(item => {

          //计划执行开始时间   ---->>>   planTimeRange
          if (item.fieldName === 'planTimeRange') {
            item.fieldType = 'date'
            item.fieldValue = item.fieldValue.split(',')
          }

          //创建时间   ---->>>   createTime
          if (item.fieldName === 'createTime') {
            item.fieldType = 'date'
          }
        })
      }

      // 处理新增的筛选条件 ————————End————————
      this.getFacilityDemandCount()
      this.$refs.mTable.setTableData(true)
    },
    modifys(row) {
      this.$router.push({ name: 'addDemandCenter', query: { type: 'modify', id: row.id } })
    },
    desPlan(node) {
      this.delVisible = true
      this.isDel = 1
      this.isMul = false
      this.selectId = node.id
    },
    async delMand(row) {
      this.currentType = 'del'
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`del${this.params.radio}`]({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.getFacilityDemandCount()
          this.$refs.mTable.setTableData(true)
        }
      })
    },
    async DelRequirement(row) {
      this.currentType = 'del'
      this.$confirm('删除该需求后，相关联的工单将被一并删除，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delFacilityDemand({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.getFacilityDemandCount()
          this.$refs.mTable.setTableData(true)
        }
      })
    },
    async submitForm(callback) {
      const { form } = this.$refs.baseStatusForm
      this.$refs.baseStatusForm.formRefs().validate(async valid => {
        if (valid) {
          const res = await api[`sendOnFacilityDemandDispatch`]({
            facilityDemandId: this.baseInfo.id,
            principalId: form.principalId,
            principalName: form.principalId ? this.userList.filter(item => item.id === +form.principalId)[0].name : ''
          })
          if (res) {
            this.$message.success('转交成功')
            this.getFacilityDemandCount()
            this.$refs.mTable.setTableData(true)
            this.delVisible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async submitFormReset(callback) {
      const { form } = this.$refs.resetForm
      this.$refs.resetForm.formRefs().validate(async valid => {
        if (valid) {
          const res = await api.modifyFacilityDemandOperate({
            sign: 0,
            demandId: this.baseInfo.id,
            content: +this.baseInfo.content === 3 ? 3 : 0,
            updateRemark: form.updateRemark
          })
          if (res) {
            this.resetVisible = false
            this.$message.success('退回成功')
            this.getFacilityDemandCount()
            this.$refs.mTable.setTableData(true)
          }
          callback(true)
        } else {
          callback()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.extra-froms ::v-deep {
  .el-form-item {
    margin-bottom: 18px !important;
  }
}

.tabs-containter {
  ::v-deep .el-tabs__header {
    height: calc(100vh - 138px);
  }

  ::v-deep .el-tabs__item.is-active {
    background: #f2f2f2;
  }

  ::v-deep #tab-0 {
    background: #60cfff;
  }
}

.tab-right {
  margin-left: 20px;
}

.scrap-wrap {
  width: 100%;

  .containter {
    display: flex;

    .wrap-left {
      width: 165px;
      margin-right: 10px;

      .wrap-left-top {
        height: 40px;
        background: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #eee;

        .img {
          display: block;
          width: 17px;
          height: 16px;
          margin: 0 10px 0 20px;
        }

        span {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #393d60;
        }
      }

      .wrap-left-bottom {
        background: #f6f7f9;
        height: calc(100vh - 178px);

        .wrap-left-li {
          padding: 0 10px;
          height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9fa1b7;
          line-height: 40px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          .tab-right {
          }
        }

        .active {
          background: #f0f3ff;
          color: #607fff;
        }
      }
    }

    .wrap-right {
      width: calc(100% - 190px);
    }
  }

  // display: inline-flex;

  .bottom {
    padding: 10px;
  }

  .m-radio-group {
    margin-left: 10px;
  }
}
</style>
