var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "system-wrapper" },
    [
      _vm._l(_vm.paramsList, function(item) {
        return [
          item.isEnabled
            ? _c("div", { key: item.id, staticClass: "common-card" }, [
                _c("div", { staticClass: "card-tit" }, [
                  _c("div", { staticClass: "card-le" }, [
                    _vm._v(_vm._s(item.name))
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "card-bot" },
                  [
                    item.childrenList
                      ? _vm._l(item.childrenList, function(child) {
                          return _c(
                            "div",
                            { key: child.id, staticClass: "item-wrapper" },
                            [
                              child.isEnabled
                                ? _c(
                                    "div",
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v(_vm._s(child.name) + "：")
                                      ]),
                                      _c(
                                        "el-radio-group",
                                        {
                                          on: { change: _vm.radioChange },
                                          model: {
                                            value: child.selectedItemIdList[0],
                                            callback: function($$v) {
                                              _vm.$set(
                                                child.selectedItemIdList,
                                                0,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "child.selectedItemIdList[0]"
                                          }
                                        },
                                        _vm._l(child.selectItemList, function(
                                          radio
                                        ) {
                                          return _c(
                                            "el-radio",
                                            {
                                              key: radio.id,
                                              attrs: { label: radio.id }
                                            },
                                            [_vm._v(_vm._s(radio.name) + " ")]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        })
                      : _vm._e(),
                    item.code === "preparation_config"
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "item-wrapper" },
                            [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("工单下发自动创建备料任务：")
                              ]),
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function($event) {
                                      return _vm.radioAssignChanges()
                                    }
                                  },
                                  model: {
                                    value: _vm.assignId,
                                    callback: function($$v) {
                                      _vm.assignId = $$v
                                    },
                                    expression: "assignId"
                                  }
                                },
                                _vm._l(_vm.assignList, function(items) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: items.id,
                                      attrs: { label: items.id }
                                    },
                                    [_vm._v(" " + _vm._s(items.name) + " ")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.assignId === 1
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "197px",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        change: function($event) {
                                          return _vm.radioWorkChanges()
                                        }
                                      },
                                      model: {
                                        value: _vm.wtypeId,
                                        callback: function($$v) {
                                          _vm.wtypeId = $$v
                                        },
                                        expression: "wtypeId"
                                      }
                                    },
                                    _vm._l(_vm.workList, function(items) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: items.id,
                                          attrs: { label: items.id }
                                        },
                                        [_vm._v(" " + _vm._s(items.name) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    item.code === "table_page_size"
                      ? _c("div", [
                          _c("div", { staticClass: "item-wrapper" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("默认条数：")
                            ]),
                            _c(
                              "div",
                              [
                                _c("CURDSelect", {
                                  ref: "CURDSelect",
                                  refInFor: true,
                                  staticStyle: {
                                    width: "300px",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    "form-type": "number",
                                    "custom-list": _vm.pageSize.value.list,
                                    "default-value": _vm.pageSize.value.default,
                                    placeholder: "输入条数"
                                  },
                                  on: {
                                    confirm: _vm.confirmPageSize,
                                    del: _vm.confirmPageSize,
                                    change: function(select) {
                                      _vm.confirmPageSize("change", "", select)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.code === "plan_config"
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "plan_header-title" }, [
                              _vm._v(" 1.优先级设置： ")
                            ]),
                            _vm._l(_vm.planTags, function(tag, index) {
                              return _c(
                                "div",
                                {
                                  key: tag.name,
                                  staticClass: "flex-cc tag",
                                  style: {
                                    color: tag.color,
                                    background: tag.color + "20"
                                  }
                                },
                                [
                                  tag.isSystem !== 1
                                    ? _c("el-button", {
                                        staticStyle: { "font-size": "16px" },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-edit-outline"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.modifyTag(tag, index)
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _c("div", [_vm._v(_vm._s(tag.name))]),
                                  tag.isSystem !== 1
                                    ? _c("el-button", {
                                        staticStyle: {
                                          "font-size": "16px",
                                          color: "#00000051"
                                        },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-error"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.delTag(tag, index)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            _c("el-button", {
                              staticStyle: {
                                "font-size": "20px",
                                position: "relative",
                                top: "2px"
                              },
                              attrs: {
                                type: "text",
                                icon: "el-icon-circle-plus-outline"
                              },
                              on: { click: _vm.addTag }
                            }),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  width: "200",
                                  trigger: "click"
                                },
                                on: { "after-enter": _vm.afterEnter },
                                model: {
                                  value: _vm.inputVisible,
                                  callback: function($$v) {
                                    _vm.inputVisible = $$v
                                  },
                                  expression: "inputVisible"
                                }
                              },
                              [
                                _c("div", { staticClass: "title flex-sb" }, [
                                  _c("div", { staticClass: "left" }, [
                                    _vm._v("新增优先级")
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "right" },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-error"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.inputVisible = false
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _c("el-input", {
                                  ref: "saveTagInput",
                                  refInFor: true,
                                  staticClass: "input-new-tag",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "优先级名称",
                                    size: "small"
                                  },
                                  model: {
                                    value: _vm.inputValue,
                                    callback: function($$v) {
                                      _vm.inputValue = $$v
                                    },
                                    expression: "inputValue"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "color-list flex-sb" },
                                  _vm._l(_vm.colors, function(color) {
                                    return _c(
                                      "div",
                                      {
                                        key: color,
                                        staticClass: "color flex-cc",
                                        style: { background: color },
                                        on: {
                                          click: function($event) {
                                            return _vm.setColor(color)
                                          }
                                        }
                                      },
                                      [
                                        _vm.currentColor === color
                                          ? _c("i", {
                                              staticClass: "el-icon-check",
                                              staticStyle: { color: "#FFFFFF" }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "footer flex-cc" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small"
                                        },
                                        on: { click: _vm.handleInputConfirm }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.currentType === "add"
                                                ? "创建"
                                                : "修改"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("br"),
                            _c("h3", { staticClass: "plan_header-title" }, [
                              _vm._v("优先级顺序：")
                            ]),
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  staticClass: "plan-drag-container",
                                  on: {
                                    start: function($event) {
                                      _vm.isDragging = true
                                    },
                                    end: _vm.dragEnd
                                  },
                                  model: {
                                    value: _vm.planTags,
                                    callback: function($$v) {
                                      _vm.planTags = $$v
                                    },
                                    expression: "planTags"
                                  }
                                },
                                "draggable",
                                _vm.dragOptions,
                                false
                              ),
                              _vm._l(_vm.planTags, function(dragTag) {
                                return _c("div", { key: dragTag.name }, [
                                  _c(
                                    "div",
                                    { style: { color: dragTag.color } },
                                    [_vm._v(_vm._s(dragTag.name))]
                                  ),
                                  _c("div", [
                                    _c("i", { staticClass: "el-icon-rank" })
                                  ])
                                ])
                              }),
                              0
                            ),
                            _c("div", { staticClass: "plan_header-title" }, [
                              _vm._v(" 2. 工序任务下发设置 ")
                            ]),
                            _c("MTable", {
                              ref: "mTable",
                              refInFor: true,
                              attrs: {
                                columns: _vm.columns,
                                height: 290,
                                "set-data-method": _vm.getTableData,
                                "columns-setting": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "issueMode",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { name: "issueGroup" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.assingChanges(
                                                    1,
                                                    row.issueMode,
                                                    row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: row.issueMode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "issueMode",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.issueMode"
                                              }
                                            },
                                            _vm._l(_vm.issueList, function(
                                              items
                                            ) {
                                              return _c(
                                                "el-radio",
                                                {
                                                  key: items.id,
                                                  attrs: { label: items.id }
                                                },
                                                [_vm._v(_vm._s(items.name))]
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  },
                                  {
                                    key: "assignMode",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { name: "assingGroup" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.assingChanges(
                                                    2,
                                                    row.assignMode,
                                                    row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: row.assignMode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "assignMode",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.assignMode"
                                              }
                                            },
                                            _vm._l(_vm.assingList, function(
                                              items
                                            ) {
                                              return _c(
                                                "el-radio",
                                                {
                                                  key: items.id,
                                                  attrs: { label: items.id }
                                                },
                                                [_vm._v(_vm._s(items.name))]
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  },
                                  {
                                    key: "parallelMode",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { name: "parallelGroup" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.assingChanges(
                                                    3,
                                                    row.parallelMode,
                                                    row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: row.parallelMode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "parallelMode",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.parallelMode"
                                              }
                                            },
                                            _vm._l(_vm.parallelList, function(
                                              items
                                            ) {
                                              return _c(
                                                "el-radio",
                                                {
                                                  key: items.id,
                                                  attrs: { label: items.id }
                                                },
                                                [_vm._v(_vm._s(items.name))]
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._l(_vm.extraList, function(item) {
                              return _c(
                                "div",
                                { key: item.id, staticClass: "item-wrapper" },
                                [
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v(_vm._s(item.name) + "：")
                                  ]),
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { name: item.name },
                                      on: {
                                        change: function($event) {
                                          return _vm.radioChanges(item)
                                        }
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    },
                                    _vm._l(item.selectItemList, function(
                                      items
                                    ) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: items.value,
                                          attrs: { label: items.value }
                                        },
                                        [_vm._v(_vm._s(items.name) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            _vm.memberCode === "mubai" ||
                            _vm.memberCode === "reagold"
                              ? _c(
                                  "div",
                                  { staticClass: "item-wrapper" },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("计划总数是否允许大于订单数：")
                                    ]),
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function($event) {
                                            return _vm.radioPlanChanges()
                                          }
                                        },
                                        model: {
                                          value: _vm.planId,
                                          callback: function($$v) {
                                            _vm.planId = $$v
                                          },
                                          expression: "planId"
                                        }
                                      },
                                      _vm._l(_vm.planList, function(items) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: items.id,
                                            attrs: { label: items.id }
                                          },
                                          [_vm._v(_vm._s(items.name) + " ")]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "plan_header-title",
                                staticStyle: { "margin-bottom": "0" }
                              },
                              [_vm._v(" 3. 外协申请设置 ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "item-wrapper" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("外协申请审批：")
                                ]),
                                _c(
                                  "el-radio-group",
                                  {
                                    on: {
                                      change: function($event) {
                                        return _vm.radioOutChanges()
                                      }
                                    },
                                    model: {
                                      value: _vm.outSourceId,
                                      callback: function($$v) {
                                        _vm.outSourceId = $$v
                                      },
                                      expression: "outSourceId"
                                    }
                                  },
                                  _vm._l(_vm.outSourceList, function(items) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: items.id,
                                        attrs: { label: items.id }
                                      },
                                      [_vm._v(_vm._s(items.name) + " ")]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            +_vm.outSourceId === 1
                              ? _c("div", { staticClass: "item-wrapper" }, [
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v("审批权限设置：")
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择",
                                            filterable: "",
                                            multiple: "",
                                            clearable: ""
                                          },
                                          on: {
                                            change: _vm.modifyDefaultOutsource
                                          },
                                          model: {
                                            value: _vm.defaultOutsource,
                                            callback: function($$v) {
                                              _vm.defaultOutsource = $$v
                                            },
                                            expression: "defaultOutsource"
                                          }
                                        },
                                        _vm._l(_vm.userList, function(item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    item.code === "inspection_update_user"
                      ? _c("div", [
                          _c("div", { staticClass: "item-wrapper" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("默认质检人员：")
                            ]),
                            _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择",
                                      filterable: "",
                                      multiple: "",
                                      clearable: ""
                                    },
                                    on: { change: _vm.modifyDefaultInspection },
                                    model: {
                                      value: _vm.defaultInspection,
                                      callback: function($$v) {
                                        _vm.defaultInspection = $$v
                                      },
                                      expression: "defaultInspection"
                                    }
                                  },
                                  _vm._l(_vm.userList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "item-wrapper" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("质检提交后可修改检验记录人员：")
                            ]),
                            _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择",
                                      filterable: "",
                                      multiple: "",
                                      clearable: ""
                                    },
                                    on: { change: _vm.modifyInspection },
                                    model: {
                                      value: _vm.inspection,
                                      callback: function($$v) {
                                        _vm.inspection = $$v
                                      },
                                      expression: "inspection"
                                    }
                                  },
                                  _vm._l(_vm.userList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "item-wrapper" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("可删除质检检验记录人员：")
                            ]),
                            _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择",
                                      filterable: "",
                                      multiple: "",
                                      clearable: ""
                                    },
                                    on: { change: _vm.deleteInspection },
                                    model: {
                                      value: _vm.inspectionDeleteUser,
                                      callback: function($$v) {
                                        _vm.inspectionDeleteUser = $$v
                                      },
                                      expression: "inspectionDeleteUser"
                                    }
                                  },
                                  _vm._l(_vm.userList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }