<template>
  <div class="board-group-container">
    <div v-for="(item, index) in list" :key="index">
      <div v-if="permission('allBoardGroup' + item.key, false)">
        <div class="type-name">{{ item.name }}</div>
        <div class="card-wrapper">
          <div v-for="(child, childIndex) in item.children" :key="'child_' + childIndex" class="card"
               title="点击右键可在新标签页打开" @contextmenu="jumpDetailNewTab(child.id)"
               @click="jumpDetailCurrentTab(child.id)">
            <div><img :src="child.img" alt=""></div>
            <div>{{ child.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const productImg = 'https://oss.mubyte.cn/static/%E7%94%9F%E4%BA%A7%E7%9C%8B%E6%9D%BF.png'
const workImg = 'https://oss.mubyte.cn/static/%E5%B7%A5%E5%8D%95%E8%BF%9B%E5%BA%A6%E7%9C%8B%E6%9D%BF.png'
const workImg2 = 'https://oss.mubyte.cn/static/%E5%B7%A5%E5%8D%95%E8%BF%9B%E5%BA%A6%E7%9C%8B%E6%9D%BF03.png'
const workImg3 = 'https://oss.mubyte.cn/static/%E5%B7%A5%E5%8D%95%E8%BF%9B%E5%BA%A6%E7%9C%8B%E6%9D%BF02.png'
const safeImg = 'https://mubyte.oss-cn-shanghai.aliyuncs.com/static/safe_board.png'
const equipmentImg = 'https://oss.mubyte.cn/static/%E8%AE%BE%E5%A4%87%E7%9C%8B%E6%9D%BF.png'
const equipmentImg2 = 'https://oss.mubyte.cn/static/%E8%AE%BE%E5%A4%87%E7%9C%8B%E6%9D%BF02.png'
const qualityImg = 'https://oss.mubyte.cn/static/%E8%B4%A8%E9%87%8F%E7%9C%8B%E6%9D%BF.png'
const callImg = 'https://oss.mubyte.cn/static/%E5%BC%82%E5%B8%B8%E7%9C%8B%E6%9D%BF.png'
const qualityImg2 = 'https://oss.mubyte.cn/static/%E8%B4%A8%E6%A3%80%E7%9C%8B%E6%9D%BF02.png'
const warehouseImg = 'https://oss.mubyte.cn/static/%E4%BB%93%E5%BA%93%E7%9C%8B%E6%9D%BF.png'
const inventoryImg = 'https://oss.mubyte.cn/static/%E5%BA%93%E5%AD%98%E7%9C%8B%E6%9D%BF.jpg'
export default {
  name: 'BoardGroup',
  data() {
    return {
      list: [],
      groupListJinshi: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [{
            id: 1,
            name: '生产看板',
            img: productImg
          }, {
            id: 2,
            name: '工单进度看板',
            img: workImg
          }]
        },
        {
          name: '设备看板',
          key: 'Equipment',
          children: [{
            id: 4,
            name: '设备看板',
            img: equipmentImg
          }, {
            id: 8,
            name: '设备看板02',
            img: equipmentImg2
          }]
        },
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 7,
            name: '仓库看板',
            img: warehouseImg
          }]
        }
      ],
      groupListBbelc: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [
            {
              id: 1,
              name: '生产看板',
              img: productImg
            }, {
              id: 2,
              name: '工单进度看板',
              img: workImg
            }]
        },
        {
          name: '异常看板',
          key: 'Call',
          children: [{
            id: 5,
            name: '异常看板',
            img: callImg
          }]
        },
        {
          name: '质检看板',
          key: 'Quanlity',
          children: [{
            id: 3,
            name: '质检看板01',
            img: qualityImg
          },
            {
              id: 6,
              name: '质检看板02',
              img: qualityImg2
            }]
        },
        {
          name: '设备看板',
          key: 'Equipment',
          children: [{
            id: 4,
            name: '设备看板',
            img: equipmentImg
          }, {
            id: 8,
            name: '设备看板02',
            img: equipmentImg2
          }]
        },
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 7,
            name: '仓库看板',
            img: warehouseImg
          }
          ]
        },
        {
          name: '库存看板',
          key: 'StockBoard',
          children: [{
            id: 9,
            name: '库存看板',
            img: inventoryImg
          }
          ]
        }
      ],
      groupList: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [{
            id: 1,
            name: '生产看板',
            img: productImg
          }, {
            id: 2,
            name: '工单进度看板',
            img: workImg
          }]
        },
        {
          name: '异常看板',
          key: 'Call',
          children: [{
            id: 5,
            name: '异常看板',
            img: callImg
          }]
        },
        {
          name: '质检看板',
          key: 'Quanlity',
          children: [{
            id: 3,
            name: '质检看板01',
            img: qualityImg
          },
            {
              id: 6,
              name: '质检看板02',
              img: qualityImg2
            }
          ]
        },
        {
          name: '设备看板',
          key: 'Equipment',
          children: [{
            id: 4,
            name: '设备看板',
            img: equipmentImg
          },
            {
              id: 8,
              name: '设备看板02',
              img: equipmentImg2
            }]
        },
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 7,
            name: '仓库看板',
            img: warehouseImg
          }]
        }
      ],
      groupListFsd: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [{
            id: 10,
            name: '工单进度看板',
            img: workImg2
          }]
        },
        {
          name: '质检看板',
          key: 'Quanlity',
          children: [
            {
              id: 66,
              name: '质检看板',
              img: qualityImg2
            }
          ]
        },
        {
          name: '设备看板',
          key: 'Equipment',
          children: [{
            id: 4,
            name: '设备看板',
            img: equipmentImg
          },
            {
              id: 8,
              name: '设备看板02',
              img: equipmentImg2
            }]
        },
        {
          name: '安全看板',
          key: 'SafeBoard',
          children: [
            {
              id: 88,
              name: '安全生产监控大屏',
              img: safeImg
            }
          ]
        }
      ],
      groupListNozoli: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [{
            id: 10,
            name: '工单进度看板',
            img: workImg2
          },{
            id: 12,
            name: '工单进度看板02',
            img: workImg3
          }]
        },
        {
          name: '质检看板',
          key: 'Quanlity',
          children: [
            {
              id: 66,
              name: '质检看板',
              img: qualityImg2
            }
          ]
        },
        {
          name: '设备看板',
          key: 'Equipment',
          children: [{
            id: 4,
            name: '设备看板',
            img: equipmentImg
          },
            {
              id: 8,
              name: '设备看板02',
              img: equipmentImg2
            }]
        },
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 7,
            name: '仓库看板',
            img: warehouseImg
          }]
        }
      ],
      groupListNewFocus: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [{
            id: 1,
            name: '生产看板',
            img: productImg
          }, {
            id: 2,
            name: '工单进度看板',
            img: workImg
          }]
        },
        {
          name: '质检看板',
          key: 'Quanlity',
          children: [{
            id: 3,
            name: '质检看板01',
            img: qualityImg
          },
            {
              id: 6,
              name: '质检看板02',
              img: qualityImg2
            }
          ]
        },
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 7,
            name: '仓库看板',
            img: warehouseImg
          }]
        }
      ],
      groupListReagold: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [{
            id: 1,
            name: '生产看板',
            img: productImg
          }, {
            id: 2,
            name: '工单进度看板',
            img: workImg
          }]
        },
        {
          name: '质检看板',
          key: 'Quanlity',
          children: [{
            id: 3,
            name: '质检看板01',
            img: qualityImg
          },
            {
              id: 6,
              name: '质检看板02',
              img: qualityImg2
            }
          ]
        },
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 7,
            name: '仓库看板',
            img: warehouseImg
          }]
        }
      ],
      groupListLingyang1: [
        {
          name: '生产车间看板',
          key: 'Production',
          children: [{
            id: 1,
            name: '生产看板',
            img: productImg
          }, {
            id: 2,
            name: '工单进度看板',
            img: workImg
          }]
        },
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 7,
            name: '仓库看板',
            img: warehouseImg
          }]
        }
      ],
      groupListLingyang2: [
        {
          name: '设备看板',
          key: 'Equipment',
          children: [{
            id: 4,
            name: '设备看板',
            img: equipmentImg
          }, {
            id: 8,
            name: '设备看板02',
            img: equipmentImg2
          }]
        }
      ],
      groupListXiewei: [
        {
          name: '仓库看板',
          key: 'Warehouse',
          children: [{
            id: 9,
            name: '库存看板',
            img: inventoryImg
          }]
        }
      ]
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.list = this.memberCode === 'bbelc' ? this.groupListBbelc :
      this.memberCode === 'jinshi' ? this.groupListJinshi :
        this.memberCode === 'newfocus' ? this.groupListNewFocus :
          this.memberCode === 'lingyang1' ? this.groupListLingyang1 :
            this.memberCode === 'lingyang2' ? this.groupListLingyang2 :
              ['reagold', 'hshq'].includes(this.memberCode) ? this.groupListReagold :
                this.memberCode === 'xwhj-sh' ? this.groupListXiewei :
                  this.memberCode === 'fsd' ? this.groupListFsd :
                    ['mom', 'nozoli', 'test-mom'].includes(this.memberCode) ? this.groupListNozoli :
                      this.groupList
  },
  methods: {
    jumpDetailCurrentTab(id) {
      this.$router.push({ path: `/board-detail/${id}` })
    },
    jumpDetailNewTab(id) {
      this.moreTabSetSessionStorage()
      const routeUrl = this.$router.resolve({ path: `/board-detail/${id}` })
      window.open(routeUrl.href, '_blank')
    },
    moreTabSetSessionStorage() {
      if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', Date.now())
      }
      window.addEventListener('storage', (event) => {
        if (event.key === 'getSessionStorage') {
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
          localStorage.removeItem('sessionStorage')
        } else {
          if (event.key === 'sessionStorage' && !sessionStorage.length) {
            const data = JSON.parse(event.newValue).value
            for (const key in data) {
              sessionStorage.setItem(key, data[key])
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.board-group-container {
  padding: 0 30px 18px 30px;
  height: 100%;
  overflow-y: auto;
  width: 100%;

  .type-name {
    color: #9597AE;
    padding: 18px 0;
  }

  .card-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;

    .card {
      width: 33%;
      padding-right: 20px;
      cursor: pointer;

      div:first-child {
        width: 100%;
        padding: 15px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
        transition: all .5s;

        img {
          width: 100%;
        }
      }

      div:last-child {
        padding: 18px 0;
        color: #393D60;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        text-align: center;
      }

      &:hover {
        div:first-child {
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
        }

        div:last-child {
          color: #607FFF;
        }
      }
    }
  }
}
</style>
